import "./import-jquery.js";
import $ from "jquery";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel';
import '@fortawesome/fontawesome-free/css/all.css';
import './css/demo.css';
import './css/style-dustu.css';
import './scss/app.scss';
import Splitting from "splitting";
import ScrollOut from "scroll-out";
import nextArrow from "./img/next-arrow.svg";
import prevArrow from "./img/prev-arrow.svg";
import nextComment from "./img/next-comment.svg";
import prevComment from "./img/prev-comment.svg";

$(document).ready(function(){


        Splitting();

        ScrollOut({
            threshold: .2,
            once: true
        });

    $('.comments-carousel').owlCarousel({
        loop:true,
        margin:40,
        nav:true,
        dots:false,
        autoplay:4000,
        navText: ["<img src='"+prevComment+"'>","<img src='"+nextComment+"'>"],
        responsive:{
            0:{
                items:1
            },
            600:{
                items:2
            }
        }
    });

    $('.blog-carousel').owlCarousel({
        loop:true,
        margin:0,
        nav:true,
        dots:false,
        autoplay:4000,
        navText: ["<img src='"+prevComment+"'>","<img src='"+nextComment+"'>"],
        items:1,
    });

    $('.slider-carousel').owlCarousel({
        loop:true,
        margin:0,
        nav:true,
        items:1,
        autoplay:3000,
        navText: ["<img src='"+prevArrow+"'>","<img src='"+nextArrow+"'>"],
        dots:false,
    })

    $(document.body).on('touchmove', onScroll); // for mobile
    $(window).on('scroll', onScroll);

    function onScroll(){
        var sticky = $('header'),
            scroll = $(window).scrollTop();

        if (scroll >= 1) sticky.addClass('sticky');
        else sticky.removeClass('sticky');
    }

    onScroll();

    const items = document.querySelectorAll(".accordion button");

    function toggleAccordion() {
        const itemToggle = this.getAttribute('aria-expanded');
        let i = 0;

        for (i = 0; i < items.length; i++) {
            items[i].setAttribute('aria-expanded', 'false');
        }

        if (itemToggle == 'false') {
            this.setAttribute('aria-expanded', 'true');
        }
    }

    items.forEach(item => item.addEventListener('click', toggleAccordion));

    $('.button_container').click(function() {
        $('.button_container').toggleClass('active');
        $('.overlay').toggleClass('open');
        $('body').toggleClass('active');
    });

    gsap.registerPlugin(ScrollTrigger);

    ScrollTrigger.batch(".svg", {
        stagger: 1,
        toggleClass: "active"
    });

    ScrollTrigger.batch(".path", {
        stagger: 1,
        toggleClass: "active"
    });

});